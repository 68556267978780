@import '../variables.scss';

.account-search-view-title-container {
  background-color: #edf0f6;
  border-radius: 7px;
  padding: 6px;
}

.account-search-view-title {
  font-size: 14px;
  font-weight: bold;
  color: #14121f;
  margin-bottom: 0;
  padding-left: 10px;
}

.account-search-view-table-container {
  padding: 6px;
}

.account-search-view-search-message {
  font-size: 12px;
}

.account-search-view-table-container td {
  padding-left: 6px;
  font-size: 12px;
  padding-bottom: 10px;
}

.account-search-view-table-td-key {
  color: #585858;
}

.account-search-view-table-td-value {
  color: #28323c;
  font-weight: $semiBold;
}
