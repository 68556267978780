.account-request-view-title-container {
  background-color: #edf0f6;
  border-radius: 7px;
  padding: 6px;
}

.account-request-view-title {
  font-size: 14px;
  font-weight: bold;
  color: #14121f;
  margin-bottom: 0;
  padding-left: 10px;
}

.account-request-view-table-container {
  padding: 6px;
}

.account-request-view-request-message {
  font-size: 12px;
}

.account-request-view-table-container td {
  padding-left: 6px;
  font-size: 12px;
  padding-bottom: 10px;
}

.account-request-view-table-td-key {
  color: #585858;
}

.account-request-view-table-td-value {
  color: #28323c;
}

.form-title-p {
  font-size: 15px;
  font-weight: 500;
}
.form-title-span {
  font-size: 14px;
}

.installation-schedule-container .form-title-span,
.Reboot-Defaults .form-title-span {
  font-size: 12px;
  margin-left: 33px;
  display: block;
}
.Custom-Defaults,
.Recommended-Defaults {
  border: 1px solid #c8c8c8;
}

.installation-schedule-container {
  margin-left: 0.1rem;
}
.scan-install label.form-check-label {
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: 500;
}
.install-schedule .snooze-alarm-time-select-date {
  width: 196px;
}
.install-schedule .snooze-alarm-time-select-date#week {
  width: 220px;
}
.toggle-switch-gis #custom-switch {
  padding: 8px;
  width: 3em;
  float: none;
  margin-left: 0;
  margin-bottom: 3px;
}
.toggle-switch-gis #custom-switch + label {
  color: #009579;
  display: block;
  font-weight: 500;
}
.toggle-switch-gis.form-switch {
  margin-left: 0.75rem;
  padding-left: 0;
  text-align: center;
}
.padding-13 {
  padding: 14px;
}
.review-acc-details {
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: center;
}
// .GIS-Account tr:first-child th:last-child {
//   text-align: center;
// }
.install-schedule .snooze-alarm-title {
  font-size: 15px;
  font-weight: 500;
}
.install-schedule-utc {
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
  margin: auto 0;
  color: #00af8e;
}

.fw-500 {
  font-weight: 500;
}
.review-confirm-modal-check {
  font-size: 12px;
  margin-left: 0.5rem;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}
.fs-12 {
  font-size: 12px;
}
.target-note {
  border: 1px solid #cdcdcd;
  padding: 5px 5px;
}

// .confirmation-warning {
//   display: flex;
//   width: 10%;
//   flex-direction: column;
//   justify-content: center;
// }
.confirmation-text {
  text-align: left;
}

//aws backup module
ul.warning-ul {
  text-align: left;
  list-style-position: inside;
}
ul.warning-ul.font-fs li {
  font-size: 0.9rem;
  color: black;
}

//Billing module
.chargeBack-action {
  color: #ababab;
  font-size: 14px;
  font-weight: bolder;
}
.chargeBack-heading {
  color: #000;
  font-weight: bolder;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-shadow: 0px 0.4px, 0px 0px, 0.5px 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.chargeBack-desc {
  color: #818181;
  font-size: 11px;
  font-weight: bolder;
}
.chargeBack-desc.details {
  color: #3ac4aa;
  font-style: italic;
}
.chargeBack-desc.details.last {
  padding-bottom: 1.5rem;
  word-break: break-all;
}
.billing-mod-icon {
  position: absolute;
  top: 28px;
  left: 1.5rem;
  color: white;
  padding: 13px 18px;
  border-radius: 34px;
  font-weight: bolder;
}
.billing-view-detail {
  font-weight: 500;
  color: #9d9d9d;
  text-decoration: underline;
  cursor: pointer;
}
.billing-view-detail:hover {
  font-weight: 500;
  color: #404040;
}
.billing--home--card:hover {
  box-shadow: 0 4px 18px rgba(33, 33, 38, 0.2);
}
.billing--home--card {
  cursor: pointer;
  margin: 12px 0px;
}
.card.billing.tooltip {
  border: 0;
  background-color: transparent;
}
.custom-card.billing--home--card {
  z-index: 9;
}
.billing.tooltiptext.tooltiptext-month {
  z-index: 999;
}
.billing-hr {
  border-bottom: 1px solid #d3d3d3;
}
.padding-top-25 {
  padding-top: 2.5rem !important;
}
.version-history table th {
  color: #3ac4aa;
}
.version-history .header h6 {
  font-weight: bolder;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 5px;
}
.version-history .header span {
  font-size: 22px;
  margin-right: 11px;
  cursor: pointer;
}
.version-history table td {
  font-weight: 500;
}
.invoice-download .padding-left-30 {
  padding-left: 30px !important;
}
.csv-download-btn,
.csv-download-btn:hover,
.csv-download-btn:active {
  color: white !important;
  background-color: rgb(70, 133, 133) !important;
  box-shadow: 0px 4px 16px #eceff5 !important;
}
.pb-2rem {
  padding-bottom: 2rem !important;
}
.gcs-dashboard {
  border: 2px solid #3ac4aa;
  padding: 1px 6px;
  background-color: #f1f5f7;
  border-radius: 5px;
  color: #3ac4aa;
  cursor: pointer;
}
.add-form-save-note {
  color: #d73838;
  font-size: 0.835rem;
}

.enable-screen-input {
  padding: 3px;
}
.enable-screen-input-container > div {
  display: inline-block;
  width: 50px;
  margin: 0 5px;
}
.enable-screen-input-container div div {
  display: inline;
}
.enable-screen-input .invalid-feedback {
  margin: 0 !important;
}
.margin-0-auto {
  margin: auto 0;
}
.fs-11 {
  font-size: 11px;
}
.fs-13 {
  font-size: 13px;
}
.enable-screen-input-container label.form-label {
  display: none;
}
.color-black {
  color: #000000;
}
.invoice-report {
  background-color: #3ac4aa;
  padding: 6px 10px;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 12px;
}
.request-widget-card {
  width: calc((100% / 5) - 0px);
}
.cursor-pointer {
  cursor: pointer;
}
.request-h5-container h5 {
  padding: 9.3px 15px;
  border: 1px solid #3ac4aa;
  margin-bottom: 6.9px;
  border-radius: 8px;
}
.combined-card-counts div {
  justify-content: space-evenly;
  padding: 6px 0;
}
.combined-card-counts div a {
  padding: 0 16px;
}
.gcs-count-logo-right {
  width: 35px;
  height: 30px;
}
.last-request-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}
.margin-2px {
  margin: 2px;
}
.batch-left-arrow {
  position: absolute;
  left: 100%;
  font-size: 0.85rem;
  font-weight: lighter;
  color: #a5a5a5;
}
th[aria-label="GCS Approval sortable"],
th[aria-label="Infosec Approval sortable"],
th[aria-label="Risk Approval sortable"],
th[aria-label="Overall Approval sortable"] {
  text-align: center;
  width: 16%;
}
.infosec-risk-or {
  bottom: 50%;
  transform: translateY(50%);
  font-weight: 600;
}

//ai request form
.ai-form-billing-info {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin-right: 0px;
  background-color: white;
  margin-left: 0px;
  padding: 20px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.margin-top--10 {
  margin-top: -10px;
}
