@import "../variables.scss";

.cloudeye-user-container {
  padding-right: 0;
  padding-left: 0;
}

.application-name-container {
  display: flex;
  justify-content: start;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
  background-color: #ffffff;
  padding: 16px 8px;
}

.cloudeye-user-menu-tabs {
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 40%;
  padding: 0;

  a {
    text-decoration: none !important;
    color: black !important;
  }

  li {
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    margin-right: 8px;
    font-size: $subtitle;
    font-weight: $semiBold;
    color: black;

    &.active {
      font-size: $subtitle;
      font-weight: $semiBold;
      color: $darkTeal;
      border-bottom: 1px solid $darkTeal;
    }
  }
}

.application-name-account-name {
  font-size: 16px;
  color: black;
  font-weight: $bold;

  i {
    font-size: 16px;
  }
}

.application-container-title {
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.speedometer-container {
  position: relative;
}

.speedometer-title {
  font-size: 14px;
  font-weight: $bold;
  text-align: center;
  width: 200px;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
}

.speedometer-value {
  font-weight: $regular;
  font-size: 11px;
  text-align: center;
}

.cloud-infrastructure-container {
  display: flex;
  flex-direction: column;
}

.cloud-infrastructure-img-container {
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 7px;
}

.cloud-infrastructure-img {
  width: 34px;
  height: 38px;
}

.cloud-infrastructure-count-text {
  color: #262729;
  font-weight: $bold;
  font-size: 30px;
}

.healthy-text {
  color: #2eb99f;
  font-size: $regularText;
  font-weight: $bold;
  text-transform: uppercase;
}

.unhealthy-text {
  color: #ff4519;
  font-size: $regularText;
  font-weight: $bold;
  text-transform: uppercase;
}

.custom-realtive {
  position: relative;
}

.custom-notify {
  position: absolute;
  right: 10px;
  top: -5px;
  background-color: red;
  border-radius: 50%;
  padding: 1px 10px;

  i {
    color: white;
    font-size: 14px;
  }
}

// Custom Application Bar
.custom-application-bar {
  position: relative;
  transition: all 0.2s ease;

  .custom-arrow-icon {
    position: absolute;
    top: 0;
    right: -10px;
    height: 19px;
    width: 19px;
    background-color: #d1e1fe;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .application-bar-text-rotate {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    height: 100vh;
    padding: 16px 8px;
    font-size: 16px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transition: all 0.2s ease;
  }
}

@media screen and (max-width: 767px) {
  .application-name-container {
    display: flex;
    justify-content: start;
    flex-direction: column;
    height: auto;
    background-color: #ffffff;
    padding: 16px 8px;
  }

  .cloudeye-user-menu-tabs {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;

    a {
      text-decoration: none !important;
      color: black !important;
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: auto;
      font-size: $subtitle;
      font-weight: $semiBold;
      color: black;

      &.active {
        font-size: $subtitle;
        font-weight: $semiBold;
        color: $darkTeal;
        border-bottom: 1px solid $darkTeal;
      }
    }
  }

  // Custom Application Bar (Mobile Part)
  .custom-application-bar {
    .custom-arrow-icon {
      display: none;
    }
  }
}
.select-category-container {
  padding: 17.5px;
  border: 0;
  border-radius: 16px;
}
.select-category-container label.form-label {
  font-weight: 600;
  font-size: 12px !important;
}
a.automation-ec2-button {
  margin: 0 7px;
  font-size: 12px;
  font-weight: 500;
}
a.automation-ec2-button:hover {
  text-decoration: none;
}
