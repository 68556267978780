@import 'variables.scss';

textarea:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='search']:focus,
.uneditable-input:focus {
  border-color: 9ce1d4 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6) !important;
  outline: 0 none !important;
}

.form-control,
.form-select {
  font-size: 14px !important;
  border: 1px solid #9ce1d4;
  // padding: 8px 0.75rem;
  padding: 8px 2.25rem 8px 0.75rem;
  // Text Overflow
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.form-label {
  font-size: 14px !important;
  color: #28323c !important;
}

.btn-primary {
  color: #fff;
  background-color: $darkTeal !important;
  border-color: $darkTeal !important;
  font-size: 11px !important;
}

.dropdown-item {
  font-size: 11px !important;
}

.btn-outline-secondary {
  border: 1px solid #9ce1d4 !important;
  padding: 8px 0.75rem !important;
}
