@import 'variables.scss';

// Toggle Switch Start

.custom-toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 17px;
}

.custom-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-toggle-slider:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .custom-toggle-slider {
  background-color: $darkTeal;
}

input:focus + .custom-toggle-slider {
  box-shadow: 0 0 1px $darkTeal;
}

input:checked + .custom-toggle-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.custom-toggle-slider.round {
  border-radius: 34px;
}

.custom-toggle-slider.round:before {
  border-radius: 50%;
}

// Toggle Switch End

// WYSWIG

.rdw-editor-main {
  background-color: white !important;
  padding: 4px 8px !important;
}

// Custom Search Input
.custom-search-input-form-control {
  font-size: 14px !important;
  border: 1px solid #9ce1d4 !important;
  width: 100% !important;
  height: 39px !important;
  padding: 8px 0.75rem 8px 40px !important;
}

.search-input-group {
  position: relative;
  display: table;
  width: 100%;
  .fa {
    position: absolute;
    margin: 11px;
  }
  .search-label {
    width: 100%;
  }
}

// File Upload

// .custom-upload-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 85px;
//   width: 100%;
//   background-color: #fff;

//   i {
//     margin-right: 1.45rem;
//     font-size: 16px;
//   }

//   p {
//     font-size: $subtitle;
//     margin-bottom: 0;
//     font-weight: $semiBold;
//     display: flex;
//     align-items: center;
//   }
// }

// .custom-upload-btn {
//   position: relative;
//   overflow: hidden;

//   input[type='file'] {
//     position: absolute;
//     top: 0;
//     right: 0;
//     min-width: 100%;
//     height: 50px;
//     font-size: 100px;
//     text-align: right;
//     filter: alpha(opacity=0);
//     opacity: 0;
//     outline: none;
//     background: white;
//     cursor: pointer;
//     display: block;
//   }
// }

// Multi Select Dropdown style

.optionListContainer .optionContainer {
  margin-bottom: 65px !important;
}

.multiselect-container .search-wrapper .chip {
  background-color: $darkTeal !important;
  color: white !important;
}

.optionContainer li:hover {
  background-color: $darkTeal !important;
}

// React Tag Input Custom

.react-tag-input__tag {
  background-color: #ddf3ee !important;

  .react-tag-input__tag__content {
    color: #3ac4aa !important;
    font-weight: $bold;
  }

  .react-tag-input__tag__remove {
    background-color: #ddf3ee !important;
  }

  .react-tag-input__tag__remove:before,
  .react-tag-input__tag__remove:after {
    background-color: #3ac4aa;
  }
}

// Custom Upload ( Refactor )

.custom-upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.custom-upload-button {
  border: 1px solid #ffffff;
  color: black;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: $semiBold;
  width: 100%;
  height: 60px;

  i {
    margin-right: 1rem;
  }
}

.custom-upload-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

// multiselecct customization only for vpc disassociation form
.zone-multi-select .optionListContainer .optionContainer {
  margin-bottom: 0px !important;
}