body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f1f5f7;
}

.custom-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  // background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #eceff5;
  border-radius: 16px;
  opacity: 1;
  padding: 16px;
}

.custom-pointer {
  cursor: pointer;
}

.checkbox-input .form-check-input {
  padding: 0 !important;
}

.form-check-input:checked {
  background-color: #3ac4aa;
  border-color: #3ac4aa;
}

/* Page Content Title */
.page-content-title {
  font-size: 16px;
  font-weight: 600;
}

/* Loader */

.custom-landing-loader {
  height: 400px;
}

.rc-tooltip-width-40 {
  width: 40%;
}

// SCSS Imports

@import 'scss/custom-font.scss';
@import 'scss/custom-form-input.scss';
@import 'scss/error-message.scss';
@import 'scss/carousel.scss';
@import 'scss/frames.scss';
@import 'scss/custom-message.scss';
@import 'scss/view-edit-box.scss';

// Custom Badge
@import 'scss/custom-badge.scss';

// Page Content Sidebar
@import 'scss/pagecontent-sidebar.scss';

// Customised Bootstrap
@import 'scss/custom-bootstrap.scss';

// Button
@import 'scss/custom-button.scss';

// Custom Pagination
@import 'scss/pagination.scss';

// Table
@import 'scss/custom-table.scss';
@import 'scss/datatables.scss';

// Full Layout
@import 'scss/full-layout/full-layout.scss';

// Sidebar Layout
@import 'scss/sidebar-layout/header.scss';
@import 'scss/sidebar-layout/sidebar.scss';
@import 'scss/sidebar-layout/pagecontent.scss';

// Canvas
@import 'scss/canvas/statusbadge.scss';
@import 'scss/canvas/offcanvasdetails.scss';
@import 'scss/canvas/canvaslegendlist.scss';
@import 'scss/canvas/legendlist.scss';

// Pages

// Cloudeye User
@import 'scss/cloudeye-user/cloudeye-user.scss';
@import 'scss/cloudeye-user/metrics.scss';
@import 'scss/cloudeye-user/alarms.scss';
@import 'scss/cloudeye-user/security.scss';
@import 'scss/cloudeye-user/logs.scss';

// Cloudeye Management
@import 'scss/cloudeye-management/cloudeye-management.scss';

// Documents
@import 'scss/documents/documents.scss';

// Okta
@import 'scss/okta/okta-sidebar.scss';
@import 'scss/okta/okta-add-request.scss';
@import 'scss/okta/okta-view-assignment.scss';

// Account Request
@import 'scss/account-request/account-request-view.scss';
@import 'scss/account-request/cloud-preference.scss';
@import 'scss/account-request/form-progress.scss';

// Self Service
@import 'scss/self-service/gitlab.scss';

// Admin Dashboard
@import 'scss/admin-dashboard/global-dashboard.scss';
@import 'scss/admin-dashboard/licenses.scss';
@import 'scss/admin-dashboard/remedyticket.scss';
@import 'scss/admin-dashboard/reportwidgets.scss';

// User Dashboard
@import 'scss/user-dashboard/user-dashboard.scss';

// CIDR
@import 'scss/cidr/cidr-view.scss';
@import 'scss/cidr/cidr-new-request.scss';

// Not Found
@import 'scss/not-found.scss';

// Account Search
@import 'scss/account-search/account-search.scss';
@import 'scss/account-search/account-search-view.scss';

// Passport Manager ( Workspace Request )
@import 'scss/passport-manager/passport-manager.scss';

// Templates
@import 'scss/templates/templates.scss';