$header-height: 58px;
$side-bar-width: 68px;

.page-content {
  background-color: #f1f5f7;
  margin-top: $header-height;
  margin-left: 0px;
}

.page-content-padding {
  margin-left: $side-bar-width;
}

/* Bigger Screen */

@media screen and (min-width: 768px) {
  .page-content {
    background-color: #f1f5f7;
    margin-top: $header-height;
    margin-left: $side-bar-width;
  }

  .page-content-padding {
    padding-left: calc(#{$side-bar-width} + 82px);
  }
}
